.leftData{
    color: rgba(14, 14, 112, 0.842);
    font-weight: bold;
    font-size: 1.3rem;
    text-align: center;
    margin-left: 11px !important;
    padding: 8px 0px !important;
    background-color: lightblue;
}

.row{
    margin-bottom: 0px !important;

}

.rightData{
    text-align: left;
    padding-left: 20px !important;
    font-size: 1.3rem;
    padding-top: 8px !important;
    width: 71.9% !important;
    padding-bottom: 7px !important;
    border-bottom: 1px solid #ccc;
}

.rightData input{
    border: none !important;
    font-size: inherit !important;
    padding: 0 !important;
    margin: 0 !important;
    text-align: center;
}

input:focus{
    outline: none !important;
}