.custom-ui{
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 40px 80px;
    background-color: white;
}

.custom-ui button{
    margin: 0px 10px !important;
}

.react-confirm-alert-overlay{
    background-color: rgba(0, 0, 0, 0.5);
}