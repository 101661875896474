/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

/* ****************************************** */
/* NEW CSS */
/* ******************************************* */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body{
  line-height: 2rem;
  height: 100vh;   
}

.content{
  padding: 15px;
}

.btn{
  border-radius: 35px; 
}
.actions{
  display: flex;
  justify-content: space-between;
  padding: 6px;
}
.actions h4{
  margin: 0;
}
.spinner{
  height: 50vh;
  display:flex;
 justify-content: center;
 align-items: center
}

.left-panel{
  position: sticky;
  top: 30%;
}

.right-panel{
  border-left: 1px solid grey;
}

footer{
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
}

@media(max-width: 600px){
  .left-panel{
      position: static;
  }
  .right-panel{
      border-left: none;
  }
}











